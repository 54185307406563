import {
  Box,
  Heading,
  HTMLChakraProps,
  Stack,
  useMediaQuery,
  useColorMode,
} from "@chakra-ui/react";
import * as React from "react";

type ExtendedProps = { 
  preserveText?: boolean; 
  hideLogo?: boolean;
  isOnRight?: boolean;
  forceWhiteLogo?: boolean;
  overrideSmallerThan600?: boolean;
};
type Props = HTMLChakraProps<"svg"> & ExtendedProps;

export const Logo = (props: Props) => {
  const [isSmallerThan600px] = useMediaQuery("(max-width: 600px)");
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Stack isInline alignItems="center" justifyContent="center">
      {
        (!isSmallerThan600px || props.overrideSmallerThan600) && !props.isOnRight && (
          <Box w={"25px"} h={"25px"} p={0}>
            {colorMode === "light" && !props.forceWhiteLogo ? (
              <svg width="100%" height="100%" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.152 31.9676L23.86 32.7304L13.9391 22.8095L15.4662 21.2817L26.152 31.9676Z" fill="black"/>
                <path d="M30.7079 33.5357L27.6487 36.5949L23.8599 32.7304L25.3113 23.542C25.6054 21.6792 25.3949 19.8008 24.7385 18.0872C24.7385 18.0872 24.5724 15.46 23.5123 14.3947L25.0542 12.9675C26.0899 14.0192 27.9796 14.8457 27.9796 14.8457C29.125 17.007 29.7317 19.4263 29.7317 21.8931C29.7317 22.6725 29.6711 23.4569 29.5479 24.2399L28.4483 31.2011L30.7079 33.5357Z" fill="#4F4F4F"/>
                <path d="M26.7988 12.9675L23.7025 16.0638C24.1208 16.7019 24.4669 17.3807 24.7376 18.0875L27.9792 14.8459C27.6341 14.1944 27.24 13.5664 26.7988 12.9675Z" fill="#2D2D2D"/>
                <path d="M26.7987 12.9678L23.7028 16.0637C23.5052 15.7624 23.2918 15.4711 23.0633 15.1892C22.8398 14.9138 22.6018 14.6484 22.3488 14.3946L25.4058 11.3362C25.9134 11.8495 26.3777 12.3953 26.7987 12.9678Z" fill="black"/>
                <path d="M15.4661 21.2817L13.939 22.8095L3.97705 12.8475L5.24961 11.0652L15.4661 21.2817Z" fill="#2D2D2D"/>
                <path d="M23.7026 11.4757L22.3487 13.1823L22.0988 12.8226C21.0297 11.7534 18.6201 11.969 18.6201 11.969C17.4148 11.5074 16.1279 11.2664 14.8222 11.2664C14.2714 11.2664 13.7176 11.309 13.1654 11.3962L3.977 12.8476L0.176636 9.00034L3.23582 5.94116L5.25172 8.00393L12.6282 6.9866C15.8363 6.54399 19.0597 7.15193 21.8435 8.65758C21.8435 8.65758 22.4714 9.49444 23.5123 10.5353C23.5397 10.5627 23.6752 11.4475 23.7026 11.4757Z" fill="black"/>
                <path d="M18.6198 11.9697C19.3265 12.2404 20.0054 12.5865 20.6435 13.0048L23.758 9.89031C23.1663 9.44294 22.5454 9.04178 21.9002 8.68921L18.6198 11.9697Z" fill="black"/>
                <path d="M25.4057 11.3362L22.3487 14.3947L22.3307 14.3767C21.8116 13.8575 21.2456 13.3997 20.6436 13.0046L23.7583 9.89062C24.3069 10.3052 24.8311 10.7594 25.3243 11.2526C25.3524 11.2807 25.3798 11.3081 25.4057 11.3362Z" fill="#2D2D2D"/>
                <path d="M32.2696 5.96689L8.80844 29.4281L8.05933 28.6789L18.6199 17.4995L31.5205 5.21777L32.2696 5.96689Z" fill="black"/>
                <path d="M7.2771 27.8977L30.7347 4.44008L31.5147 5.22009L8.05711 28.6777L7.2771 27.8977Z" fill="#4F4F4F"/>
                <path d="M10.3563 31.3627L4.98125 36.7377L3.59766 33.1399L5.65148 30.2805L9.24954 27.4888L10.3563 31.3627Z" fill="#2D2D2D"/>
                <path d="M9.24964 27.4881V27.4888L3.59777 33.14L0 31.7564L5.37502 26.3813L9.24964 27.4881Z" fill="black"/>
                <path d="M36.7072 0L36.6949 0.0425387L28.7287 8.00881L28.7013 8.00593L28.1057 2.48383L36.7072 0Z" fill="#2D2D2D"/>
                <path d="M36.695 0.0424805L34.2235 8.60141L28.7288 8.00875L32.07 3.79346L36.695 0.0424805Z" fill="black"/>
              </svg>
            ) : (
              <svg width="199*" height="100%" viewBox="0 0 286 286" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M203.591 248.865L185.747 254.804L108.514 177.57L120.402 165.676L203.591 248.865Z" fill="#D8D8D8"/>
                <path d="M239.06 261.073L215.245 284.889L185.749 254.804L197.048 183.273C199.337 168.771 197.699 154.148 192.589 140.808C192.589 140.808 191.296 120.355 183.043 112.062L195.047 100.951C203.109 109.138 217.821 115.573 217.821 115.573C226.737 132.399 231.46 151.233 231.46 170.436C231.46 176.504 230.989 182.611 230.029 188.706L221.469 242.899L239.06 261.073Z" fill="#F3F3F3"/>
                <path d="M208.627 100.951L184.523 125.055C187.78 130.023 190.474 135.308 192.581 140.81L217.818 115.574C215.131 110.503 212.063 105.613 208.627 100.951Z" fill="#F3F3F3"/>
                <path d="M208.627 100.953L184.525 125.055C182.987 122.709 181.326 120.441 179.547 118.247C177.807 116.103 175.955 114.037 173.984 112.061L197.783 88.2515C201.735 92.2479 205.349 96.4968 208.627 100.953Z" fill="#B0B0B0"/>
                <path d="M120.403 165.676L108.514 177.57L30.9609 100.016L40.8677 86.1411L120.403 165.676Z" fill="#CBCBCB"/>
                <path d="M184.523 89.3363L173.983 102.623L172.038 99.8217C163.715 91.4984 144.956 93.1766 144.956 93.1766C135.573 89.5832 125.555 87.7074 115.39 87.7074C111.102 87.7074 106.791 88.0386 102.491 88.7177L30.9606 100.017L1.375 70.066L25.1906 46.2505L40.8842 62.309L98.3099 54.3892C123.285 50.9434 148.379 55.6762 170.05 67.3977C170.05 67.3977 174.938 73.9126 183.042 82.0159C183.255 82.2292 184.31 89.1174 184.523 89.3363Z" fill="white"/>
                <path d="M144.953 93.1833C150.455 95.2903 155.741 97.9851 160.708 101.241L184.954 76.9951C180.348 73.5123 175.514 70.3892 170.491 67.6445L144.953 93.1833Z" fill="white"/>
                <path d="M197.782 88.2519L173.984 112.062L173.843 111.922C169.802 107.88 165.396 104.316 160.709 101.24L184.957 76.998C189.228 80.2255 193.309 83.7616 197.148 87.6008C197.367 87.8197 197.58 88.033 197.782 88.2519Z" fill="#BFBFBF"/>
                <path d="M251.218 46.4524L68.574 229.096L62.7422 223.265L144.956 136.233L245.386 40.6206L251.218 46.4524Z" fill="white"/>
                <path d="M56.6523 217.181L239.269 34.5643L245.341 40.6367L62.7247 223.253L56.6523 217.181Z" fill="#F3F3F3"/>
                <path d="M80.6232 244.156L38.779 286L28.0078 257.992L43.9967 235.731L72.0074 213.998L80.6232 244.156Z" fill="#F3F3F3"/>
                <path d="M72.008 213.992V213.997L28.0084 257.991L0 247.22L41.8442 205.376L72.008 213.992Z" fill="white"/>
                <path d="M285.765 0L285.669 0.331161L223.652 62.3482L223.439 62.3257L218.803 19.3365L285.765 0Z" fill="#F3F3F3"/>
                <path d="M285.667 0.331055L266.426 66.9619L223.65 62.3481L249.662 29.5322L285.667 0.331055Z" fill="white"/>
              </svg>
            
            )}
          </Box>
        )
      }
      {props.preserveText ? (
          // <Heading fontFamily="Rubik, mono" fontSize="20px" whiteSpace="nowrap">
          <Heading as="p" fontSize="20px" whiteSpace="nowrap">
            Tweet Hunter
          </Heading>
        ) : (
          !isSmallerThan600px && (
            // <Heading fontFamily="Rubik, mono" fontSize="20px" whiteSpace="nowrap">
            <Heading as="p" fontSize="20px" whiteSpace="nowrap">
              Tweet Hunter
            </Heading>
          )
      )}
      {
        props.isOnRight && (
          <Box w={"25px"} h={"25px"} p={0}>
            {colorMode === "light" ? (
              <svg width="100%" height="100%" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M26.152 31.9676L23.86 32.7304L13.9391 22.8095L15.4662 21.2817L26.152 31.9676Z" fill="black"/>
                <path d="M30.7079 33.5357L27.6487 36.5949L23.8599 32.7304L25.3113 23.542C25.6054 21.6792 25.3949 19.8008 24.7385 18.0872C24.7385 18.0872 24.5724 15.46 23.5123 14.3947L25.0542 12.9675C26.0899 14.0192 27.9796 14.8457 27.9796 14.8457C29.125 17.007 29.7317 19.4263 29.7317 21.8931C29.7317 22.6725 29.6711 23.4569 29.5479 24.2399L28.4483 31.2011L30.7079 33.5357Z" fill="#4F4F4F"/>
                <path d="M26.7988 12.9675L23.7025 16.0638C24.1208 16.7019 24.4669 17.3807 24.7376 18.0875L27.9792 14.8459C27.6341 14.1944 27.24 13.5664 26.7988 12.9675Z" fill="#2D2D2D"/>
                <path d="M26.7987 12.9678L23.7028 16.0637C23.5052 15.7624 23.2918 15.4711 23.0633 15.1892C22.8398 14.9138 22.6018 14.6484 22.3488 14.3946L25.4058 11.3362C25.9134 11.8495 26.3777 12.3953 26.7987 12.9678Z" fill="black"/>
                <path d="M15.4661 21.2817L13.939 22.8095L3.97705 12.8475L5.24961 11.0652L15.4661 21.2817Z" fill="#2D2D2D"/>
                <path d="M23.7026 11.4757L22.3487 13.1823L22.0988 12.8226C21.0297 11.7534 18.6201 11.969 18.6201 11.969C17.4148 11.5074 16.1279 11.2664 14.8222 11.2664C14.2714 11.2664 13.7176 11.309 13.1654 11.3962L3.977 12.8476L0.176636 9.00034L3.23582 5.94116L5.25172 8.00393L12.6282 6.9866C15.8363 6.54399 19.0597 7.15193 21.8435 8.65758C21.8435 8.65758 22.4714 9.49444 23.5123 10.5353C23.5397 10.5627 23.6752 11.4475 23.7026 11.4757Z" fill="black"/>
                <path d="M18.6198 11.9697C19.3265 12.2404 20.0054 12.5865 20.6435 13.0048L23.758 9.89031C23.1663 9.44294 22.5454 9.04178 21.9002 8.68921L18.6198 11.9697Z" fill="black"/>
                <path d="M25.4057 11.3362L22.3487 14.3947L22.3307 14.3767C21.8116 13.8575 21.2456 13.3997 20.6436 13.0046L23.7583 9.89062C24.3069 10.3052 24.8311 10.7594 25.3243 11.2526C25.3524 11.2807 25.3798 11.3081 25.4057 11.3362Z" fill="#2D2D2D"/>
                <path d="M32.2696 5.96689L8.80844 29.4281L8.05933 28.6789L18.6199 17.4995L31.5205 5.21777L32.2696 5.96689Z" fill="black"/>
                <path d="M7.2771 27.8977L30.7347 4.44008L31.5147 5.22009L8.05711 28.6777L7.2771 27.8977Z" fill="#4F4F4F"/>
                <path d="M10.3563 31.3627L4.98125 36.7377L3.59766 33.1399L5.65148 30.2805L9.24954 27.4888L10.3563 31.3627Z" fill="#2D2D2D"/>
                <path d="M9.24964 27.4881V27.4888L3.59777 33.14L0 31.7564L5.37502 26.3813L9.24964 27.4881Z" fill="black"/>
                <path d="M36.7072 0L36.6949 0.0425387L28.7287 8.00881L28.7013 8.00593L28.1057 2.48383L36.7072 0Z" fill="#2D2D2D"/>
                <path d="M36.695 0.0424805L34.2235 8.60141L28.7288 8.00875L32.07 3.79346L36.695 0.0424805Z" fill="black"/>
              </svg>
            ) : (
              <svg width="199*" height="100%" viewBox="0 0 286 286" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M203.591 248.865L185.747 254.804L108.514 177.57L120.402 165.676L203.591 248.865Z" fill="#D8D8D8"/>
                <path d="M239.06 261.073L215.245 284.889L185.749 254.804L197.048 183.273C199.337 168.771 197.699 154.148 192.589 140.808C192.589 140.808 191.296 120.355 183.043 112.062L195.047 100.951C203.109 109.138 217.821 115.573 217.821 115.573C226.737 132.399 231.46 151.233 231.46 170.436C231.46 176.504 230.989 182.611 230.029 188.706L221.469 242.899L239.06 261.073Z" fill="#F3F3F3"/>
                <path d="M208.627 100.951L184.523 125.055C187.78 130.023 190.474 135.308 192.581 140.81L217.818 115.574C215.131 110.503 212.063 105.613 208.627 100.951Z" fill="#F3F3F3"/>
                <path d="M208.627 100.953L184.525 125.055C182.987 122.709 181.326 120.441 179.547 118.247C177.807 116.103 175.955 114.037 173.984 112.061L197.783 88.2515C201.735 92.2479 205.349 96.4968 208.627 100.953Z" fill="#B0B0B0"/>
                <path d="M120.403 165.676L108.514 177.57L30.9609 100.016L40.8677 86.1411L120.403 165.676Z" fill="#CBCBCB"/>
                <path d="M184.523 89.3363L173.983 102.623L172.038 99.8217C163.715 91.4984 144.956 93.1766 144.956 93.1766C135.573 89.5832 125.555 87.7074 115.39 87.7074C111.102 87.7074 106.791 88.0386 102.491 88.7177L30.9606 100.017L1.375 70.066L25.1906 46.2505L40.8842 62.309L98.3099 54.3892C123.285 50.9434 148.379 55.6762 170.05 67.3977C170.05 67.3977 174.938 73.9126 183.042 82.0159C183.255 82.2292 184.31 89.1174 184.523 89.3363Z" fill="white"/>
                <path d="M144.953 93.1833C150.455 95.2903 155.741 97.9851 160.708 101.241L184.954 76.9951C180.348 73.5123 175.514 70.3892 170.491 67.6445L144.953 93.1833Z" fill="white"/>
                <path d="M197.782 88.2519L173.984 112.062L173.843 111.922C169.802 107.88 165.396 104.316 160.709 101.24L184.957 76.998C189.228 80.2255 193.309 83.7616 197.148 87.6008C197.367 87.8197 197.58 88.033 197.782 88.2519Z" fill="#BFBFBF"/>
                <path d="M251.218 46.4524L68.574 229.096L62.7422 223.265L144.956 136.233L245.386 40.6206L251.218 46.4524Z" fill="white"/>
                <path d="M56.6523 217.181L239.269 34.5643L245.341 40.6367L62.7247 223.253L56.6523 217.181Z" fill="#F3F3F3"/>
                <path d="M80.6232 244.156L38.779 286L28.0078 257.992L43.9967 235.731L72.0074 213.998L80.6232 244.156Z" fill="#F3F3F3"/>
                <path d="M72.008 213.992V213.997L28.0084 257.991L0 247.22L41.8442 205.376L72.008 213.992Z" fill="white"/>
                <path d="M285.765 0L285.669 0.331161L223.652 62.3482L223.439 62.3257L218.803 19.3365L285.765 0Z" fill="#F3F3F3"/>
                <path d="M285.667 0.331055L266.426 66.9619L223.65 62.3481L249.662 29.5322L285.667 0.331055Z" fill="white"/>
              </svg>
            
            )}
          </Box>
        )
      }
    </Stack>
  );
};
